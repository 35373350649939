$platform-name: 'friendsofthelakedistrict2019';

$brand-primary: #414b50;
$brand-secondary: #96b4be;
$brand-tertiary: #cd120a;
$brand-four: #9B322E; // darker red for links
$donate-colour: $brand-tertiary;
$grey-lightest: #f3f3f3;
$grey-dark: $brand-primary;

// layout
$gap-width: 20px;
$site-bleed: 20px;
$border-radius: 2px;

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;

// Buttons
$btn-padding-x: 1.5em;
$btn-padding-y: 0.75em;
$btn-hover-style: lighten;
$donate-btn-colour: #fff;

// Logo
$logo: 'logo-2025.png';
$logo-retina: 'logo_retina.png';
$logo-width: 125px;
$logo-height: 143px;

// Typography
$font-family-base: 'Cabin', sans-serif;
$headings-font-family: 'Cabin', sans-serif;
$font-size-base: 1.1rem;
$font-size-h1: 2.2em;
$font-size-h2: 2em !default;
$font-size-h3: 1.75em !default;
$font-size-h4: 1.5em !default;
$font-size-h5: 1.25em !default;
$font-size-h6: 1em !default;

// Links
$link-colour: $brand-four;

// Header
$header-background-colour: $brand-secondary;
$tagline-enabled: true;
$tagline-font-size: 1em;
$tagline-font-weight: normal;
$header-search-enabled: false;
$header-content-margin-top: 0;
$header-content-margin-bottom: 0;
$header-content-padding-top: $spacer;
$header-content-padding-bottom: $spacer;

// Social
$social-icons-background-colour: transparent;
$social-icons-header-colour: $brand-primary;

// Menu admin - Can't add margin to this?
$menu-admin-background-colour: transparent;
$menu-admin-absolute: true;
$menu-admin-background-colour: $brand-secondary;
$menu-admin-link-colour: $brand-primary;

// Navigation
$nav-normal-max-width: 100%;
// $nav-normal-contents-max-width:
$nav-background-colour: $brand-primary;
$nav-normal-align-items: left;
$nav-normal-margin-bottom: 0;
$nav-top-level-item-colour: #fff;
$nav-top-level-item-hover-background-colour: darken($brand-primary, 5%);
$nav-top-level-chevrons-enabled: true;
$nav-top-level-item-font-size: 1.1em;

// Submenus
$nav-submenu-background-colour: $brand-secondary;
$nav-submenu-item-colour: #fff;

// Carousel - Could use some control over appeal buttons
$carousel-max-width: 100%;
$carousel-details-padding: $spacer * 3;
$carousel-details-background-colour: $brand-primary;
$carousel-details-max-width: 600px;
$carousel-heading-colour: #fff;
$carousel-summary-colour: #fff;
$carousel-details-margin-y: -2rem;
$carousel-details-margin-x: 3rem;
$carousel-read-more-background-colour: $brand-secondary;
$carousel-read-more-colour: $brand-primary;
$carousel-controls-button-border-radius: 0;

// Home intro
$home-intro-max-width: 900px;
$home-intro-background-colour: #fff;
$home-intro-padding-y: $spacer * 2;

// Cards
$card-hover-box-shadow: 0px 0px 10px #dddddd;
$card-heading-font-size: $font-size-h5;
$card-heading-colour: $brand-primary;
$card-details-padding: $spacer * 2;

// Home features
$home-features-background-colour: $grey-lightest;
$home-features-padding-top: $spacer * 3;
$home-features-padding-bottom: $spacer * 3;

// Impact stats
// $impact-stats-overall-container-direction: row;
$impact-stats-padding-y: $spacer * 2;
$impact-stats-heading-text-align: left;
$impact-stats-heading-margin-bottom: $spacer * 1;

// Home feeds
$home-feeds-background-colour: $grey-lightest;
$home-feeds-padding-top: $spacer * 2;
$home-feeds-padding-bottom: $spacer * 2;

// Sidebars - off
$sidebar-enabled: false;
$post-content-max-width: 900px;

// Footer
$footer-background-colour: $brand-secondary;
$footer-link-colour: $brand-primary;
$footer-prefab: 1;

// Subsites
$subsite-enabled: true;
// $subsite-hide-main-header: true;
$subsite-nav-background-colour: $brand-secondary;
$subsite-logo: title;
$subsite-nav-margin-bottom: 0;

// Listing pages
$listing-intro-max-width: 980px;

// Donation form
$donation-amount-min-width: 220px;

// Breadcrumbs
$breadcrumb-enabled: false;


$keeping-in-touch-enabled: true;
$keeping-in-touch-via-email-enabled: true;
$keeping-in-touch-via-telephone-enabled: false;
$keeping-in-touch-via-sms-enabled: false;
$keeping-in-touch-via-post-enabled: false;

// Cookie consent
$cookie-consent-position: bottom;
$cookie-consent-background-colour: #000;
$cookie-consent-colour: text-contrast($cookie-consent-background-colour);
$cookie-consent-font-family: $font-family-base;
$cookie-consent-btn-background-colour: $brand-tertiary;
$cookie-consent-btn-text-colour: text-contrast($brand-tertiary);

// Totaliser
$totaliser-gift-aid-enabled: false;