// MCTA
.mainCallToActionButtons a.cta-button,
.mainCallToActionButtons a.cta-button.cta2 {
  padding-right: 40px;
}

a.cta-button.donate:before {
  font-family: 'FontAwesome';
  content: '\f004';
  position: absolute;
  right: 10px;
  top: 30%;
}

a.cta-button.cta2:before {
  font-family: 'FontAwesome';
  content: '\f007';
  position: absolute;
  right: 15px;
  top: 30%;
}

// MenuAdmin - Mobile
@media (max-width: 576px) {
  .menuAdminContainer {
    margin-top: 0 !important;
    position: relative;
    background-color: #414b50;
    #menuAdmin a { color: $white; }
  }
  .headerContent {
    margin-top: 0 !important;
    margin-bottom: .5rem !important;
  }
}

// Search in social Icons
.socialIcons .search:before {
  content: '\f002';
}

// HomeIntro cta-button
.homeIntro a.cta-button {
  font-size: 1rem;
  text-decoration: none;
  color: #fff;
}

// Homepage stats border
.homefeaturecategory-homeboximpactstats {
  border-bottom: 5px solid $brand-tertiary;
}

// Home feeds read all buttons
.feedBtnWrapper {
  max-width: $container-max-width;
  margin: 0px auto 0 auto;
  padding-left: 11px;
}

// Hide donor wrapper
.appealsList .donorsListWrapper {
  display: none;
}

// Footer
.footerTwitter {
  display: none;
}
.pageFooter {
  font-size: 1rem;
}

// Subsite Promo
.SubsitePromoewsletter {
  text-align: center;
  .subsitePromoContent {
    max-width: 900px;
    padding: 0 $spacer;
    margin: 0 auto;
    .cta-button {
      background-color: $brand-secondary;
      text-decoration: none;
      &:hover {
        background-color: darken($brand-secondary, 10%);
      }
    }
  }
}

// Hide Subsite promo on Subsites
.subsite .SubsitePromoewsletter {
  display: none;
}

// Subsites
.subsiteFeeds .socialIcons {
  justify-content: center;
}

// Reduced Subsite top bar
.subsite {
  .menuAdminContainer,
  .mainCallToAction,
  .menuMain {
    display: none;
  }
  .pageHeader {
    .headerContent {
      margin-top: 0;
    }
    .mainLogo {
      margin-top: $spacer;
      margin-bottom: $spacer;
    }
  }
  // .mainLogo {
  //   background-image: url("../assets/logo_subsite_retina.png");
  //   height: 50px;
  // }
  .pageFooter .Signup,
  .pageFooter .Footercontactdetails,
  .pageFooter .Footercredits {
    display: none;
  }
}

// Mailchimp
#mc_embed_signup {
  .mc-field-group {
    margin-bottom: 10px;
  }
  .mc-field-group label {
    min-width: 140px;
  }
  .indicates-required {
    font-size: 0.8rem;
  }
}

// Hide All Products from Shop to compensate for non-standard shop flow
.departmentList .hierarchyLevel1:first-child {
  display: none;
}

// hide Email GDPR
// .formQuestion.contactViaEmail {
//   display: none;
// }

